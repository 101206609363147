import React from "react"
import ProductsLayout from "../../components/productsLayout"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import ImageGallery from "react-image-gallery"
import Img1 from "../../images/pvcojacanja2.jpg"
import Img2 from "../../images/pvcojacanja3.jpg"
import Logo1 from "../../images/logo1.png"
import Logo2 from "../../images/logo2.jpg"
import Logo3 from "../../images/logo3.png"
import Logo4 from "../../images/logo4.jpg"
import Logo5 from "../../images/logo5.jpg"
import Logo6 from "../../images/logo6.png"
import Logo7 from "../../images/logo7.jpg"
import Logo8 from "../../images/logo8.jpg"
import Logo9 from "../../images/logo9.png"
import Logo10 from "../../images/logo10.png"
import Head from "../../components/head"

import Carousel, { Dots } from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"

const PvcOjacanja = () => {
  const images = [
    {
      original: `${Img1}`,
      thumbnail: `${Img1}`,
    },
    {
      original: `${Img2}`,
      thumbnail: `${Img2}`,
    },
  ]
  return (
    <Layout>
      <Head title="PVC Ojaćanja" />
      <div className="products-layout">
        <section className="hero is-link">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <h1 className="title">Ojačanja za PVC stolariju</h1>
                </div>
                <div className="column">
                  <nav className="breadcrumb is-right" aria-label="breadcrumbs">
                    <ul>
                      <li>
                        <Link to="/proizvodi">Proizvodi</Link>
                      </li>
                      <li class="is-active">
                        <a href="#" aria-current="page">
                          Ojačanja za PVC stolariju
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ProductsLayout>
          <div className="columns products-list ">
            <div className="column">
              <ImageGallery
                additionalClass="box"
                infinite={false}
                showNav={false}
                thumbnailPosition="bottom"
                lazyLoad={true}
                items={images}
                showFullscreenButton={false}
                showPlayButton={false}
                originalClass="main-img"
              />
            </div>
            <div className="column">
              <p>
                <b>Ojačanja za pvc stolariju</b> izrađuju se od pocinkovanog
                lima maksimalne debljine 2mm. Postupak za izradu je hladno
                oblikovanje iz beskonačne trake, koja se vrši na linijama za
                profilisanje. Ovakvim postupkom se postiže izuzetna čvrstoća
                izlaznog profila, a povrsina materijala ne pokazuje vidne
                tragove oblikovanja. Obezbeđena je visoka tačnost dimenzija,
                oblika i geometrije po celoj dužini. Po završetku valjanja
                ojačanja se seku na potrebnu dužinu.
              </p>
              <br />
              <div className="columns is-multiline">
                <div className="column is-full">
                  <p>
                    Limovi od kojih se izrađuju PVC ojačanja su kvalitetna DX51D
                    Zn po EN-u, sa zateznom čvrstoćom u granicama između 350 -
                    400 N/mm2 i nanosom cinka od 100 - 140 g/m2.
                  </p>
                </div>
                <div className="column is-full">
                  <p>
                    Pored postojućeg asortimana, moguće je vršiti izradu
                    ojačanja po željenom obliku i dimenzijama. Fleksibilna
                    tehnologija nam omogućava da za vrlo kratko vreme
                    isprojektujemo i izradimo nove alate.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="columns products-list"
            style={{ paddingBottom: "100px" }}
          >
            <div className="column">
              <Carousel slidesPerPage={6} autoPlay={2000} infinite>
                <img src={Logo1} alt="" />
                <img src={Logo2} alt="" />
                <img src={Logo3} alt="" />
                <img src={Logo4} alt="" />
                <img src={Logo5} alt="" />
                <img src={Logo6} alt="" />
                <img src={Logo7} alt="" />
                <img src={Logo8} alt="" />
                <img src={Logo9} alt="" />
                <img src={Logo10} alt="" />
              </Carousel>
            </div>
          </div>
        </ProductsLayout>
      </div>
    </Layout>
  )
}

export default PvcOjacanja
